<template>
  <b-container class="mt-4">
    <div class="row">
      <div class="col-12">
        <h4>List of SFTP Clients</h4>
      </div>
    </div>
    <b-row v-if="loading">
      <b-col cols="12">
        <b-spinner></b-spinner>
      </b-col>
    </b-row>
    <div class="row mt-3" v-else>
      <div class="col-12">
        <b-form-group label="Filter" label-for="filter-input" label-cols-sm="1" label-size="sm">
          <b-input-group size="sm">
            <b-form-input
              id="filter-input" v-model="search" @keydown="handleKeydown" type="search" placeholder="Type to Search"></b-form-input>
          </b-input-group>
        </b-form-group>
      </div>
      <div class="col-12">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Folder</th>
              <th>Encrypt Outbound Files</th>
              <th>Compress Outbound Files</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in items" :key="i">
              <td>{{ item.client_folder }}</td>
              <td>{{ !!+item.encrypt_files }}</td>
              <td>{{ !!+item.compress_files }}</td>
              <td>
                <div class="d-flex">
                  <b-button v-b-tooltip.hover title="Edit client configuration" size="sm" variant="light"
                    class="client-entry-action mr-2" :to="'/sftp/clients/' + item.id">
                    <feather type="edit-2"></feather>
                  </b-button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </b-container>
</template>

<script>

import Vue from 'vue';
import { mapGetters } from 'vuex';
import { debounce } from 'lodash';
import * as R from 'ramda';

export default {
  name: 'SFTPClientsConfig',
  async beforeMount() {
    if (!this.isSftpSettingsEnabled) {
      this.$router.push('/not-found');
      return;
    }

    await this.fetchClients();
  },
  data() {
    return {
      loading: false,
      search: '',
      items: [],
    };
  },
  computed: {
    ...mapGetters({
      isSftpSettingsEnabled: 'Core/isSftpSettingsEnabled',
    }),
  },
  created() {
    this.searchEvent = debounce(this.searchEvent, 350, {
      leading: false,
      trailing: true,
    });
  },
  methods: {
    handleKeydown() {
      this.searchEvent();
    },
    searchEvent() {
      if (this.search.length === 0) {
        this.fetchClients();
        return;
      }

      if (!this.search || this.search.length < 3) {
        return;
      }

      this.fetchClients(this.search);
    },
    async fetchClients(search = '') {
      this.loading = true;
      let res;
      try {
        if (search !== '') {
          res = await this.$store.dispatch('Sftp/Client/searchClients', { name: search });
        } else {
          res = await this.$store.dispatch('Sftp/Client/getClients');
        }
        this.items = res.data;
      } catch (err) {
        Vue.prototype.$noty.error(R.pathOr(err.message, [ 'response', 'data', 'message' ], err));
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
tbody tr {
  line-height: 75px;
  min-height: 75px;
  height: 75px;
}

.client-entry-action {
  min-width: initial;
  background-color: transparent;
  border: none;
}

.not-available-item {
  border-radius: 8px;
  background-color: $almost-white;
  padding: 5px;
  font-size: 12px;
}

.client-logo {
  max-width: 75px;

  img {
    width: 100%;
  }
}
</style>

<style>
tr.client-row {
  height: 15vh;
}
</style>
